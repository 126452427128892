import clsx from 'clsx';
import { useFormik } from 'formik';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';
import { Button, InputField, ModalPopup } from '../../../components';
import style from './step2SignUp.module.scss';

import { updateUserData } from '../../../store/auth/authSlice';

import { BreadcrumbBack, LoadingSpinner, Logo } from '../../../components';
import { LOGIN_PAGE_URL, PASSWORD_REGEX, SIGN_UP_PAGE_URL } from '../../../constants';
import { postChangePasswordUser, postRegisterUser } from '../../../store/auth/authActions';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import TermsAndConditionsText from '../termsAndConditions/TermsAndConditionsText';
import PasswordSuggestion from './passwordSuggestion/passwordSuggestion';

type Step2SignUpProps = {
  handleClickOnNextBtn: () => void;
  handleClickOnBackBtn: () => void;
  actionToPerform: 'user/register' | 'user/changePassword';
  title: string;
  submitBtnLabel?: string;
};

const Step2SignUp: React.FunctionComponent<Step2SignUpProps> = ({
  handleClickOnNextBtn,
  handleClickOnBackBtn,
  actionToPerform,
  title,
  submitBtnLabel = 'Create an account'
}) => {
  const userState = useAppSelector((state) => state.auth.user);
  const { loading, error, success } = useAppSelector((state) => state.auth);

  const [hasBigSmallLetter, setHasBigSmallLetter] = useState(false);
  const [showTermsAndConditions, setShowTermsAndConditions] = useState(false);
  const [hasDigit, setHasDigit] = useState(false);
  const [hasSymbol, setHasSymbol] = useState(false);

  const dispatch = useAppDispatch();
  const formik = useFormik({
    validateOnMount: true,
    initialValues: {
      ...userState
    },
    validationSchema: Yup.object({
      password: Yup.string()
        .required('Password is required')
        .test('customerEmailPhone', '', function (value: any, form: any) {
          if (value && /(?=.*[a-z])(?=.*[A-Z])/.test(value)) {
            setHasBigSmallLetter(true);
          } else {
            setHasBigSmallLetter(false);
          }
          if (/\d/.test(value)) {
            setHasDigit(true);
          } else {
            setHasDigit(false);
          }
          if (/(?=.*\W)/.test(value)) {
            setHasSymbol(true);
          } else {
            setHasSymbol(false);
          }
          const isLongerThenMin = value?.length >= 8 || false;

          return hasSymbol && hasBigSmallLetter && hasSymbol && isLongerThenMin;
        })
        .min(8, 'Required  length is 8 characters.')
        .matches(PASSWORD_REGEX, 'Please use English letters only'),
      confirmPassword: Yup.string()
        .required('Password confirmation is required')
        .oneOf([Yup.ref('password'), null], 'Passwords do not match')
    }),

    onSubmit: async (values) => {
      let result;
      if (actionToPerform === 'user/register') {
        result = await dispatch(postRegisterUser(values));
      } else if (actionToPerform === 'user/changePassword') {
        result = await dispatch(postChangePasswordUser(values));
      }

      if (
        result?.type === 'user/changePassword/fulfilled' ||
        result?.type === 'user/register/fulfilled'
      ) {
        const updatedState = await dispatch(updateUserData(values));
        handleClickOnNextBtn();
      }
      setShowTermsAndConditions(false);
    }
  });

  return (
    <div className={style.pageContainer}>
      {loading && <LoadingSpinner />}
      {!loading && (
        <div className={style.container}>
          <div className={style.logoContainer}>
            <Logo type="orange" />
          </div>
          <BreadcrumbBack handleOnClick={() => handleClickOnBackBtn && handleClickOnBackBtn()} />
          <h1 className={style.passwordTitle}>{title}</h1>
          <form onSubmit={formik.handleSubmit} id="set-Password-form">
            {/* password field */}
            <div className={style.inputGroup}>
              <label htmlFor="password">
                New password<span>*</span>
              </label>
              <InputField
                id="password"
                name="password"
                type="password"
                placeholder="8+ characters required"
                visibilityControl={true}
                classnamesProps={[
                  formik.touched.password && formik.errors.password && 'globalErrorBorder',
                  formik.touched.password && !formik.errors.password && 'globalValidBorder'
                ]}
                isValid={formik.touched.password && !formik.errors.password}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.password}
              />
              {formik.touched.password && formik.errors.password ? (
                <>
                  <PasswordSuggestion
                    isMissingDigit={!hasDigit}
                    isMissingBigSmallLetter={!hasBigSmallLetter}
                    isMissingSymbol={!hasSymbol}
                  />
                  <div className={style.fieldError}>{formik.errors.password}</div>
                </>
              ) : (
                <PasswordSuggestion
                  untouchedState={true}
                  isMissingDigit={false}
                  isMissingBigSmallLetter={false}
                  isMissingSymbol={false}
                />
              )}
            </div>

            {/* confirmPassword field */}
            <div className={style.inputGroup}>
              <label htmlFor="confirmPassword">
                Confirm password<span>*</span>
              </label>
              <InputField
                id="confirmPassword"
                name="confirmPassword"
                type="password"
                placeholder="8+ characters required"
                visibilityControl={true}
                classnamesProps={[
                  formik.touched.confirmPassword &&
                    formik.errors.confirmPassword &&
                    'globalErrorBorder',
                  formik.touched.confirmPassword &&
                    !formik.errors.confirmPassword &&
                    'globalValidBorder'
                ]}
                isValid={formik.touched.confirmPassword && !formik.errors.confirmPassword}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.confirmPassword}
              />
              {formik.touched.confirmPassword && formik.errors.confirmPassword ? (
                <div className={style.fieldError}>{formik.errors.confirmPassword}</div>
              ) : null}
            </div>

            {/* next button */}
            <div className={style.inputGroup}>
              <Button
                isSecondary
                text={submitBtnLabel}
                type={actionToPerform === 'user/changePassword' ? 'submit' : 'button'}
                disabled={!formik.isValid}
                handleOnClick={() =>
                  actionToPerform === 'user/changePassword' ? null : setShowTermsAndConditions(true)
                }
              />
              <ModalPopup
                customClassInsideContainer={style.modalSmaller}
                open={showTermsAndConditions}
                handleClose={() => setShowTermsAndConditions(false)}
                children={
                  <div>
                    <TermsAndConditionsText />
                    <div className={style.confirmBtnRow}>
                      <Button
                        isSecondaryEmpty
                        text={'Reject'}
                        type={'button'}
                        handleOnClick={() => setShowTermsAndConditions(false)}
                      />
                      <Button
                        isSecondary
                        text={'Accept'}
                        type={'submit'}
                        form="set-Password-form"
                      />
                    </div>
                  </div>
                }
              />
            </div>

            {submitBtnLabel === 'Create an account' ? (
              //  {/* go to login page */}
              <div className={clsx(style.inputGroup, style.haveAccount)}>
                <p>Have an account?</p> <Link to={LOGIN_PAGE_URL}>Log in</Link>
              </div>
            ) : (
              // {/* go to signup page */}
              <div className={clsx(style.inputGroup, style.haveAccount, style.createAccount)}>
                <Link to={SIGN_UP_PAGE_URL}>Create an account</Link>
              </div>
            )}
          </form>
        </div>
      )}
    </div>
  );
};

export default Step2SignUp;
