import React from 'react';
import style from './termsAndConditions.module.scss';
const TermsAndConditionsText: React.FunctionComponent<any> = ({}) => {
  return (
    <div className={style.termsContainer}>
      <>
        <p
          dir="ltr"
          style={{
            lineHeight: '1.38',
            textAlign: 'center',
            marginTop: '0pt',
            marginBottom: '0pt'
          }}
        >
          <span
            style={{
              fontSize: '16pt',
              fontFamily: 'Calibri,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 700,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre-wrap'
            }}
          >
            IMPaCT Care
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: '1.38',
            textAlign: 'center',
            marginTop: '0pt',
            marginBottom: '0pt'
          }}
        >
          <span
            style={{
              fontSize: '16pt',
              fontFamily: 'Calibri,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 700,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'underline',
              WebkitTextDecorationSkip: 'none',
              textDecorationSkipInk: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre-wrap'
            }}
          >
            Referral Program Rules
          </span>
        </p>
        <p>
          <br />
        </p>
        <ol style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}>
          <li
            dir="ltr"
            style={{
              listStyleType: 'decimal',
              fontSize: '12pt',
              fontFamily: 'Calibri,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 700,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre'
            }}
            aria-level={1}
          >
            <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '0pt', marginBottom: '0pt' }}>
              <span
                style={{
                  fontSize: '12pt',
                  fontFamily: 'Calibri,sans-serif',
                  color: '#000000',
                  backgroundColor: 'transparent',
                  fontWeight: 700,
                  fontStyle: 'normal',
                  fontVariant: 'normal',
                  textDecoration: 'none',
                  verticalAlign: 'baseline',
                  whiteSpace: 'pre-wrap'
                }}
              >
                Program Overview
              </span>
            </p>
          </li>
        </ol>
        {/* <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '0pt', marginBottom: '0pt' }}>
          <span
            style={{
              fontSize: '12pt',
              fontFamily: 'Calibri,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 700,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre-wrap'
            }}
          >
            &nbsp;
          </span>
        </p> */}
        <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '0pt', marginBottom: '0pt' }}>
          <span
            style={{
              fontSize: '12pt',
              fontFamily: 'Calibri,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 400,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre-wrap'
            }}
          >
            The IMPaCT Care Referral Program ("Referral Program") is offered by IMPaCT Care, Inc.
            (“IMPaCT Care”) and provides Recruiters the opportunity to refer eligible individuals
            (“Referred CHW Candidates”) to apply to join the IMPaCT Care community as community
            health workers (“CHWs”) employed by our partner organizations.
          </span>
        </p>
        <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '0pt', marginBottom: '0pt' }}>
          <span
            style={{
              fontSize: '12pt',
              fontFamily: 'Calibri,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 400,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre-wrap'
            }}
          >
            &nbsp;
          </span>
        </p>
        <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '0pt', marginBottom: '0pt' }}>
          <span
            style={{
              fontSize: '12pt',
              fontFamily: 'Calibri,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 400,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre-wrap'
            }}
          >
            Please read these Program Rules carefully to understand your respective rights and
            obligations. We may update or change eligibility criteria, restrictions, and
            requirements at any time.
          </span>
        </p>
        <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '0pt', marginBottom: '0pt' }}>
          <span
            style={{
              fontSize: '12pt',
              fontFamily: 'Calibri,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 400,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre-wrap'
            }}
          >
            &nbsp;
          </span>
        </p>
        <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '0pt', marginBottom: '0pt' }}>
          <span
            style={{
              fontSize: '12pt',
              fontFamily: 'Calibri,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 400,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre-wrap'
            }}
          >
            By accepting and sharing your referral link or code, you are agreeing to be bound by
            these Program Rules. Any violation of these Program Rules may prevent you from
            participating in the IMPaCT Care referral program (now or in the future) and the
            forfeiture or retraction of money or other rewards earned through the program.
          </span>
        </p>
        <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '0pt', marginBottom: '0pt' }}>
          <span
            style={{
              fontSize: '12pt',
              fontFamily: 'Calibri,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 400,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre-wrap'
            }}
          >
            &nbsp;
          </span>
        </p>
        <ol style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }} start={2}>
          <li
            dir="ltr"
            style={{
              listStyleType: 'decimal',
              fontSize: '12pt',
              fontFamily: 'Calibri,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 700,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre'
            }}
            aria-level={1}
          >
            <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '0pt', marginBottom: '0pt' }}>
              <span
                style={{
                  fontSize: '12pt',
                  fontFamily: 'Calibri,sans-serif',
                  color: '#000000',
                  backgroundColor: 'transparent',
                  fontWeight: 700,
                  fontStyle: 'normal',
                  fontVariant: 'normal',
                  textDecoration: 'none',
                  verticalAlign: 'baseline',
                  whiteSpace: 'pre-wrap'
                }}
              >
                Eligibility
              </span>
            </p>
          </li>
        </ol>
        {/* <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '0pt', marginBottom: '0pt' }}>
          <span
            style={{
              fontSize: '12pt',
              fontFamily: 'Calibri,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 700,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre-wrap'
            }}
          >
            &nbsp;
          </span>
        </p> */}
        <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '0pt', marginBottom: '0pt' }}>
          <span
            style={{
              fontSize: '12pt',
              fontFamily: 'Calibri,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 400,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre-wrap'
            }}
          >
            The Referral Program is open to all individuals who are legal residents of the United
            States and are 18 years of age or older.&nbsp;
          </span>
        </p>
        <p>
          <br />
        </p>
        <ol style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }} start={3}>
          <li
            dir="ltr"
            style={{
              listStyleType: 'decimal',
              fontSize: '12pt',
              fontFamily: 'Calibri,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 700,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre'
            }}
            aria-level={1}
          >
            <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '0pt', marginBottom: '0pt' }}>
              <span
                style={{
                  fontSize: '12pt',
                  fontFamily: 'Calibri,sans-serif',
                  color: '#000000',
                  backgroundColor: 'transparent',
                  fontWeight: 700,
                  fontStyle: 'normal',
                  fontVariant: 'normal',
                  textDecoration: 'none',
                  verticalAlign: 'baseline',
                  whiteSpace: 'pre-wrap'
                }}
              >
                Referral Awards
              </span>
            </p>
          </li>
        </ol>
        {/* <p>
          <br />
        </p> */}
        <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '0pt', marginBottom: '0pt' }}>
          <span
            style={{
              fontSize: '12pt',
              fontFamily: 'Calibri,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 400,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre-wrap'
            }}
          >
            Recruiters can earn the following Referral Awards for referring qualified CHW candidates
            to IMPaCT Care:
          </span>
        </p>
        <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '0pt', marginBottom: '0pt' }}>
          <span
            style={{
              fontSize: '12pt',
              fontFamily: 'Calibri,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 400,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre-wrap'
            }}
          >
            &nbsp;
          </span>
        </p>
        <ul style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}>
          <li
            dir="ltr"
            style={{
              listStyleType: 'disc',
              fontSize: '12pt',
              fontFamily: 'Calibri,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 400,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre'
            }}
            aria-level={1}
          >
            <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '0pt', marginBottom: '0pt' }}>
              <span
                style={{
                  fontSize: '12pt',
                  fontFamily: 'Calibri,sans-serif',
                  color: '#000000',
                  backgroundColor: 'transparent',
                  fontWeight: 400,
                  fontStyle: 'normal',
                  fontVariant: 'normal',
                  textDecoration: 'none',
                  verticalAlign: 'baseline',
                  whiteSpace: 'pre-wrap'
                }}
              >
                $10 for each fully completed application by a qualified Referred CHW Candidate (up
                to a maximum of 25 such applications; see Maximum Referral Awards section
                below).&nbsp;
              </span>
            </p>
          </li>
          <li
            dir="ltr"
            style={{
              listStyleType: 'disc',
              fontSize: '12pt',
              fontFamily: 'Calibri,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 400,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre'
            }}
            aria-level={1}
          >
            <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '0pt', marginBottom: '0pt' }}>
              <span
                style={{
                  fontSize: '12pt',
                  fontFamily: 'Calibri,sans-serif',
                  color: '#000000',
                  backgroundColor: 'transparent',
                  fontWeight: 400,
                  fontStyle: 'normal',
                  fontVariant: 'normal',
                  textDecoration: 'none',
                  verticalAlign: 'baseline',
                  whiteSpace: 'pre-wrap'
                }}
              >
                $100 for each
              </span>
              <span
                style={{
                  fontSize: '12pt',
                  fontFamily: 'Calibri,sans-serif',
                  color: '#000000',
                  backgroundColor: 'transparent',
                  fontWeight: 400,
                  fontStyle: 'normal',
                  fontVariant: 'normal',
                  textDecoration: 'line-through',
                  WebkitTextDecorationSkip: 'none',
                  textDecorationSkipInk: 'none',
                  verticalAlign: 'baseline',
                  whiteSpace: 'pre-wrap'
                }}
              >
                &nbsp;
              </span>
              <span
                style={{
                  fontSize: '12pt',
                  fontFamily: 'Calibri,sans-serif',
                  color: '#000000',
                  backgroundColor: 'transparent',
                  fontWeight: 400,
                  fontStyle: 'normal',
                  fontVariant: 'normal',
                  textDecoration: 'none',
                  verticalAlign: 'baseline',
                  whiteSpace: 'pre-wrap'
                }}
              >
                qualified Referred CHW Candidate who completes and advances past the screening
                interview.&nbsp;
              </span>
            </p>
          </li>
          <li
            dir="ltr"
            style={{
              listStyleType: 'disc',
              fontSize: '12pt',
              fontFamily: 'Calibri,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 400,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre'
            }}
            aria-level={1}
          >
            <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '0pt', marginBottom: '0pt' }}>
              <span
                style={{
                  fontSize: '12pt',
                  fontFamily: 'Calibri,sans-serif',
                  color: '#000000',
                  backgroundColor: 'transparent',
                  fontWeight: 400,
                  fontStyle: 'normal',
                  fontVariant: 'normal',
                  textDecoration: 'none',
                  verticalAlign: 'baseline',
                  whiteSpace: 'pre-wrap'
                }}
              >
                $1000 for each Referred CHW Candidate that is hired by IMPaCT Care or its partner
                organizations.
              </span>
            </p>
          </li>
        </ul>
        <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '0pt', marginBottom: '0pt' }}>
          <span
            style={{
              fontSize: '12pt',
              fontFamily: 'Calibri,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 400,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre-wrap'
            }}
          >
            &nbsp;
          </span>
        </p>
        <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '0pt', marginBottom: '0pt' }}>
          <span
            style={{
              fontSize: '12pt',
              fontFamily: 'Calibri,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 400,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre-wrap'
            }}
          >
            The requirements for a fully completed application and advancement past the screening
            interview will be determined in IMPaCT Care’s sole discretion. CHW eligibility and
            approval are determined at the sole discretion of IMPaCT Care and is not guaranteed.
          </span>
        </p>
        <p>
          <br />
        </p>
        <ol style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }} start={4}>
          <li
            dir="ltr"
            style={{
              listStyleType: 'decimal',
              fontSize: '12pt',
              fontFamily: 'Calibri,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 700,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre'
            }}
            aria-level={1}
          >
            <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '0pt', marginBottom: '0pt' }}>
              <span
                style={{
                  fontSize: '12pt',
                  fontFamily: 'Calibri,sans-serif',
                  color: '#000000',
                  backgroundColor: 'transparent',
                  fontWeight: 700,
                  fontStyle: 'normal',
                  fontVariant: 'normal',
                  textDecoration: 'none',
                  verticalAlign: 'baseline',
                  whiteSpace: 'pre-wrap'
                }}
              >
                Requirements for Earning a Referral Award
              </span>
            </p>
          </li>
        </ol>
        {/* <p>
          <br />
        </p> */}
        <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '0pt', marginBottom: '0pt' }}>
          <span
            style={{
              fontSize: '12pt',
              fontFamily: 'Calibri,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 400,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre-wrap'
            }}
          >
            You may refer qualified CHW candidates by distributing your IMPaCT Care referral link.
            If an applicant has already applied to IMPaCT Care or does not use your referral link
            when applying, you will not receive attribution for the referral, they will not be
            considered your Referred CHW Candidate, and you will not be eligible for any Referral
            Award for that individual. Only one person can earn a Referral Award for a single
            Referred CHW Candidate. In the event that multiple persons claim credit for a particular
            referral, IMPaCT Care has full discretion to determine which person earned such referral
            according to IMPaCT's systems and processes.
          </span>
        </p>
        <p>
          <br />
        </p>
        <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '0pt', marginBottom: '0pt' }}>
          <span
            style={{
              fontSize: '12pt',
              fontFamily: 'Calibri,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 400,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre-wrap'
            }}
          >
            To receive a Referral Award for referring a CHW candidate to IMPaCT Care, your Referred
            CHW Candidate must:
          </span>
        </p>
        <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '0pt', marginBottom: '0pt' }}>
          <span
            style={{
              fontSize: '12pt',
              fontFamily: 'Calibri,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 400,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre-wrap'
            }}
          >
            &nbsp;
          </span>
        </p>
        <ul style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}>
          <li
            dir="ltr"
            style={{
              listStyleType: 'disc',
              fontSize: '12pt',
              fontFamily: 'Calibri,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 400,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre'
            }}
            aria-level={1}
          >
            <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '0pt', marginBottom: '0pt' }}>
              <span
                style={{
                  fontSize: '12pt',
                  fontFamily: 'Calibri,sans-serif',
                  color: '#000000',
                  backgroundColor: 'transparent',
                  fontWeight: 400,
                  fontStyle: 'normal',
                  fontVariant: 'normal',
                  textDecoration: 'none',
                  verticalAlign: 'baseline',
                  whiteSpace: 'pre-wrap'
                }}
              >
                Meet all the conditions and requirements IMPaCT Care has for earning the Referral
                Award, including those set forth in the Referral Awards section above or otherwise
                disclosed on our website or to you directly
              </span>
            </p>
          </li>
          <li
            dir="ltr"
            style={{
              listStyleType: 'disc',
              fontSize: '12pt',
              fontFamily: 'Calibri,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 400,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre'
            }}
            aria-level={1}
          >
            <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '0pt', marginBottom: '0pt' }}>
              <span
                style={{
                  fontSize: '12pt',
                  fontFamily: 'Calibri,sans-serif',
                  color: '#000000',
                  backgroundColor: 'transparent',
                  fontWeight: 400,
                  fontStyle: 'normal',
                  fontVariant: 'normal',
                  textDecoration: 'none',
                  verticalAlign: 'baseline',
                  whiteSpace: 'pre-wrap'
                }}
              >
                Use your referral link when they apply.
              </span>
            </p>
          </li>
          <li
            dir="ltr"
            style={{
              listStyleType: 'disc',
              fontSize: '12pt',
              fontFamily: 'Calibri,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 400,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre'
            }}
            aria-level={1}
          >
            <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '0pt', marginBottom: '0pt' }}>
              <span
                style={{
                  fontSize: '12pt',
                  fontFamily: 'Calibri,sans-serif',
                  color: '#000000',
                  backgroundColor: 'transparent',
                  fontWeight: 400,
                  fontStyle: 'normal',
                  fontVariant: 'normal',
                  textDecoration: 'none',
                  verticalAlign: 'baseline',
                  whiteSpace: 'pre-wrap'
                }}
              >
                Take any other actions reasonably required by IMPaCT Care.
              </span>
            </p>
          </li>
        </ul>
        <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '0pt', marginBottom: '0pt' }}>
          <span
            style={{
              fontSize: '12pt',
              fontFamily: 'Calibri,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 400,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre-wrap'
            }}
          >
            &nbsp;
          </span>
        </p>
        <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '0pt', marginBottom: '0pt' }}>
          <span
            style={{
              fontSize: '12pt',
              fontFamily: 'Calibri,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 400,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre-wrap'
            }}
          >
            IMPaCT Care reserves the right to withhold, deny or cancel any Referral Award and/or
            suspend or terminate your participation in the Referral Program if we, in our sole
            discretion, deem the award of any Referral Award as fraudulent, abusive, unethical,
            suspicious or otherwise inconsistent with these Program Rules or any other applicable
            laws or regulations.
          </span>
        </p>
        <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '0pt', marginBottom: '0pt' }}>
          <span
            style={{
              fontSize: '12pt',
              fontFamily: 'Calibri,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 400,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre-wrap'
            }}
          >
            &nbsp;
          </span>
        </p>
        <ol style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }} start={5}>
          <li
            dir="ltr"
            style={{
              listStyleType: 'decimal',
              fontSize: '12pt',
              fontFamily: 'Calibri,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 700,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre'
            }}
            aria-level={1}
          >
            <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '0pt', marginBottom: '0pt' }}>
              <span
                style={{
                  fontSize: '12pt',
                  fontFamily: 'Calibri,sans-serif',
                  color: '#000000',
                  backgroundColor: 'transparent',
                  fontWeight: 700,
                  fontStyle: 'normal',
                  fontVariant: 'normal',
                  textDecoration: 'none',
                  verticalAlign: 'baseline',
                  whiteSpace: 'pre-wrap'
                }}
              >
                Maximum Referral Awards
              </span>
            </p>
          </li>
        </ol>
        {/* <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '0pt', marginBottom: '0pt' }}>
          <span
            style={{
              fontSize: '12pt',
              fontFamily: 'Calibri,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 400,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre-wrap'
            }}
          >
            &nbsp;
          </span>
        </p> */}
        <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '0pt', marginBottom: '0pt' }}>
          <span
            style={{
              fontSize: '12pt',
              fontFamily: 'Calibri,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 400,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre-wrap'
            }}
          >
            IMPaCT Care reserves the right to set a maximum amount of Referral Awards you can earn
            per week (or any other time increment) for referring CHWs. It is your responsibility to
            review the Program Rules for the current maximum limits.&nbsp;
          </span>
        </p>
        <p>
          <br />
        </p>
        <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '0pt', marginBottom: '0pt' }}>
          <span
            style={{
              fontSize: '12pt',
              fontFamily: 'Calibri,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 400,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre-wrap'
            }}
          >
            In addition:
          </span>
        </p>
        <ul style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}>
          <li
            dir="ltr"
            style={{
              listStyleType: 'disc',
              fontSize: '12pt',
              fontFamily: 'Calibri,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 400,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre'
            }}
            aria-level={1}
          >
            <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '0pt', marginBottom: '0pt' }}>
              <span
                style={{
                  fontSize: '12pt',
                  fontFamily: 'Calibri,sans-serif',
                  color: '#000000',
                  backgroundColor: 'transparent',
                  fontWeight: 400,
                  fontStyle: 'normal',
                  fontVariant: 'normal',
                  textDecoration: 'none',
                  verticalAlign: 'baseline',
                  whiteSpace: 'pre-wrap'
                }}
              >
                After you earn Referral Awards for 25 completed applications, IMPaCT will do a
                quality check of your referred candidates. If you are referring great candidates,
                IMPaCT will invite you to continue to recruit. If not, we will cap your Referral
                Awards at that time.&nbsp;
              </span>
            </p>
          </li>
          <li
            dir="ltr"
            style={{
              listStyleType: 'disc',
              fontSize: '12pt',
              fontFamily: 'Calibri,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 400,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre'
            }}
            aria-level={1}
          >
            <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '0pt', marginBottom: '0pt' }}>
              <span
                style={{
                  fontSize: '12pt',
                  fontFamily: 'Calibri,sans-serif',
                  color: '#000000',
                  backgroundColor: 'transparent',
                  fontWeight: 400,
                  fontStyle: 'normal',
                  fontVariant: 'normal',
                  textDecoration: 'none',
                  verticalAlign: 'baseline',
                  whiteSpace: 'pre-wrap'
                }}
              >
                Participation in the Referral Program is subject to IMPaCT's sole discretion.
              </span>
            </p>
          </li>
        </ul>
        <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '0pt', marginBottom: '0pt' }}>
          <span
            style={{
              fontSize: '12pt',
              fontFamily: 'Calibri,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 400,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre-wrap'
            }}
          >
            &nbsp;
          </span>
        </p>
        <ol style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }} start={6}>
          <li
            dir="ltr"
            style={{
              listStyleType: 'decimal',
              fontSize: '12pt',
              fontFamily: 'Calibri,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 700,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre'
            }}
            aria-level={1}
          >
            <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '0pt', marginBottom: '0pt' }}>
              <span
                style={{
                  fontSize: '12pt',
                  fontFamily: 'Calibri,sans-serif',
                  color: '#000000',
                  backgroundColor: 'transparent',
                  fontWeight: 700,
                  fontStyle: 'normal',
                  fontVariant: 'normal',
                  textDecoration: 'none',
                  verticalAlign: 'baseline',
                  whiteSpace: 'pre-wrap'
                }}
              >
                Reward Payments and Taxes
              </span>
            </p>
          </li>
        </ol>
        {/* <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '0pt', marginBottom: '0pt' }}>
          <span
            style={{
              fontSize: '12pt',
              fontFamily: 'Calibri,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 400,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre-wrap'
            }}
          >
            &nbsp;
          </span>
        </p> */}
        <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '0pt', marginBottom: '0pt' }}>
          <span
            style={{
              fontSize: '12pt',
              fontFamily: 'Calibri,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 400,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre-wrap'
            }}
          >
            Referral Awards will be paid out to the Recruiter within 30 days of the completion of
            the qualifying event (completed application, completed interview, or hire). Payments
            will be made via prepaid debit card, electronic transfer, or any other method deemed
            appropriate by IMPaCT Care.&nbsp;
          </span>
        </p>
        <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '0pt', marginBottom: '0pt' }}>
          <span
            style={{
              fontSize: '12pt',
              fontFamily: 'Calibri,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 400,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre-wrap'
            }}
          >
            &nbsp;
          </span>
        </p>
        <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '0pt', marginBottom: '0pt' }}>
          <span
            style={{
              fontSize: '12pt',
              fontFamily: 'Calibri,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 400,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre-wrap'
            }}
          >
            The Referral Award may qualify as taxable income for you. To be eligible for payment,
            you must supply IMPaCT Care with a fully-completed Form W-9.
          </span>
          <span
            style={{
              fontSize: '12pt',
              fontFamily: 'Calibri,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 700,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre-wrap'
            }}
          >
            &nbsp;
          </span>
          <span
            style={{
              fontSize: '12pt',
              fontFamily: 'Calibri,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 400,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre-wrap'
            }}
          >
            You are responsible for any federal or state taxes resulting from your participation in
            the Referral Program. IMPaCT Care will not withhold any taxes from the awarded amounts.
          </span>
          <span
            style={{
              fontSize: '12pt',
              fontFamily: 'Calibri,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 400,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre-wrap'
            }}
          >
            <span className="Apple-tab-span" style={{ whiteSpace: 'pre' }}>
              &nbsp; &nbsp;&nbsp;
            </span>
          </span>
        </p>
        <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '0pt', marginBottom: '0pt' }}>
          <span
            style={{
              fontSize: '12pt',
              fontFamily: 'Calibri,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 400,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre-wrap'
            }}
          >
            &nbsp;
          </span>
        </p>
        <ol style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }} start={7}>
          <li
            dir="ltr"
            style={{
              listStyleType: 'decimal',
              fontSize: '12pt',
              fontFamily: 'Calibri,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 700,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre'
            }}
            aria-level={1}
          >
            <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '0pt', marginBottom: '0pt' }}>
              <span
                style={{
                  fontSize: '12pt',
                  fontFamily: 'Calibri,sans-serif',
                  color: '#000000',
                  backgroundColor: 'transparent',
                  fontWeight: 700,
                  fontStyle: 'normal',
                  fontVariant: 'normal',
                  textDecoration: 'none',
                  verticalAlign: 'baseline',
                  whiteSpace: 'pre-wrap'
                }}
              >
                Restrictions on Advertising and Promotion
              </span>
            </p>
          </li>
        </ol>
        {/* <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '0pt', marginBottom: '0pt' }}>
          <span
            style={{
              fontSize: '12pt',
              fontFamily: 'Calibri,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 400,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre-wrap'
            }}
          >
            &nbsp;
          </span>
        </p> */}
        <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '0pt', marginBottom: '0pt' }}>
          <span
            style={{
              fontSize: '12pt',
              fontFamily: 'Calibri,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 400,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre-wrap'
            }}
          >
            IMPaCT Care wants you to share your referral link and earn Referral Awards, but there
            are a few things to keep in mind. You agree that you will not:
          </span>
        </p>
        <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '0pt', marginBottom: '0pt' }}>
          <span
            style={{
              fontSize: '12pt',
              fontFamily: 'Calibri,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 400,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre-wrap'
            }}
          >
            &nbsp;
          </span>
        </p>
        <ul style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}>
          <li
            dir="ltr"
            style={{
              listStyleType: 'disc',
              fontSize: '12pt',
              fontFamily: 'Calibri,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 400,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre'
            }}
            aria-level={1}
          >
            <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '0pt', marginBottom: '0pt' }}>
              <span
                style={{
                  fontSize: '12pt',
                  fontFamily: 'Calibri,sans-serif',
                  color: '#000000',
                  backgroundColor: 'transparent',
                  fontWeight: 400,
                  fontStyle: 'normal',
                  fontVariant: 'normal',
                  textDecoration: 'none',
                  verticalAlign: 'baseline',
                  whiteSpace: 'pre-wrap'
                }}
              >
                Sell, your referral link in any manner or make it available to the general public
                (such as by posting it on a coupon website, or using it as part of a job
                application); you may, however, share your link using your own social media handle
                or in social media groups of which you are a member provided you comply with the
                rules of such group.
              </span>
            </p>
          </li>
          <li
            dir="ltr"
            style={{
              listStyleType: 'disc',
              fontSize: '12pt',
              fontFamily: 'Calibri,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 400,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre'
            }}
            aria-level={1}
          >
            <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '0pt', marginBottom: '0pt' }}>
              <span
                style={{
                  fontSize: '12pt',
                  fontFamily: 'Calibri,sans-serif',
                  color: '#000000',
                  backgroundColor: 'transparent',
                  fontWeight: 400,
                  fontStyle: 'normal',
                  fontVariant: 'normal',
                  textDecoration: 'none',
                  verticalAlign: 'baseline',
                  whiteSpace: 'pre-wrap'
                }}
              >
                Try to get Referred CHW Candidates by spamming, bulk emailing, or sending large
                numbers of unsolicited emails.&nbsp;
              </span>
            </p>
          </li>
          <li
            dir="ltr"
            style={{
              listStyleType: 'disc',
              fontSize: '12pt',
              fontFamily: 'Calibri,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 400,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre'
            }}
            aria-level={1}
          >
            <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '0pt', marginBottom: '0pt' }}>
              <span
                style={{
                  fontSize: '12pt',
                  fontFamily: 'Calibri,sans-serif',
                  color: '#000000',
                  backgroundColor: 'transparent',
                  fontWeight: 400,
                  fontStyle: 'normal',
                  fontVariant: 'normal',
                  textDecoration: 'none',
                  verticalAlign: 'baseline',
                  whiteSpace: 'pre-wrap'
                }}
              >
                Use, display, or manipulate IMPaCT Care logos, trademarks, and copyright-protected
                works in any way, except as to identify yourself as an IMPaCT Care Recruiter.
              </span>
            </p>
          </li>
          <li
            dir="ltr"
            style={{
              listStyleType: 'disc',
              fontSize: '12pt',
              fontFamily: 'Calibri,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 400,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre'
            }}
            aria-level={1}
          >
            <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '0pt', marginBottom: '0pt' }}>
              <span
                style={{
                  fontSize: '12pt',
                  fontFamily: 'Calibri,sans-serif',
                  color: '#000000',
                  backgroundColor: 'transparent',
                  fontWeight: 400,
                  fontStyle: 'normal',
                  fontVariant: 'normal',
                  textDecoration: 'none',
                  verticalAlign: 'baseline',
                  whiteSpace: 'pre-wrap'
                }}
              >
                Create or register any (i) businesses, (ii) URLs, (iii) domain names, (iv) software
                application names or titles, or (v) social media handles or profiles that include
                the words "IMPaCT Care" or any words that are confusingly similar to IMPaCT Care's
                trademarks.
              </span>
            </p>
          </li>
          <li
            dir="ltr"
            style={{
              listStyleType: 'disc',
              fontSize: '12pt',
              fontFamily: 'Calibri,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 400,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre'
            }}
            aria-level={1}
          >
            <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '0pt', marginBottom: '0pt' }}>
              <span
                style={{
                  fontSize: '12pt',
                  fontFamily: 'Calibri,sans-serif',
                  color: '#000000',
                  backgroundColor: 'transparent',
                  fontWeight: 400,
                  fontStyle: 'normal',
                  fontVariant: 'normal',
                  textDecoration: 'none',
                  verticalAlign: 'baseline',
                  whiteSpace: 'pre-wrap'
                }}
              >
                Use IMPaCT Care's trademarks as your social media profile picture or wallpaper or
                use any of IMPaCT Care's copyright-protected works (such as graphics, photos,
                images, drawings, and screenshots from our website or app) without our express
                written permission.
              </span>
            </p>
          </li>
          <li
            dir="ltr"
            style={{
              listStyleType: 'disc',
              fontSize: '12pt',
              fontFamily: 'Calibri,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 400,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre'
            }}
            aria-level={1}
          >
            <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '0pt', marginBottom: '0pt' }}>
              <span
                style={{
                  fontSize: '12pt',
                  fontFamily: 'Calibri,sans-serif',
                  color: '#000000',
                  backgroundColor: 'transparent',
                  fontWeight: 400,
                  fontStyle: 'normal',
                  fontVariant: 'normal',
                  textDecoration: 'none',
                  verticalAlign: 'baseline',
                  whiteSpace: 'pre-wrap'
                }}
              >
                Purchase keywords (including, but not limited to Google AdWords) that contain any of
                IMPaCT Care's trademarks.
              </span>
            </p>
          </li>
          <li
            dir="ltr"
            style={{
              listStyleType: 'disc',
              fontSize: '12pt',
              fontFamily: 'Calibri,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 400,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre'
            }}
            aria-level={1}
          >
            <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '0pt', marginBottom: '0pt' }}>
              <span
                style={{
                  fontSize: '12pt',
                  fontFamily: 'Calibri,sans-serif',
                  color: '#000000',
                  backgroundColor: 'transparent',
                  fontWeight: 400,
                  fontStyle: 'normal',
                  fontVariant: 'normal',
                  textDecoration: 'none',
                  verticalAlign: 'baseline',
                  whiteSpace: 'pre-wrap'
                }}
              >
                Use automated systems or bots through any channel to distribute, post, or otherwise
                share your referral link or code.
              </span>
            </p>
          </li>
          <li
            dir="ltr"
            style={{
              listStyleType: 'disc',
              fontSize: '12pt',
              fontFamily: 'Calibri,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 400,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre'
            }}
            aria-level={1}
          >
            <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '0pt', marginBottom: '0pt' }}>
              <span
                style={{
                  fontSize: '12pt',
                  fontFamily: 'Calibri,sans-serif',
                  color: '#000000',
                  backgroundColor: 'transparent',
                  fontWeight: 400,
                  fontStyle: 'normal',
                  fontVariant: 'normal',
                  textDecoration: 'none',
                  verticalAlign: 'baseline',
                  whiteSpace: 'pre-wrap'
                }}
              >
                Use scripts or programmed or automatic dialers to send invites or otherwise share
                your referral link or code.
              </span>
            </p>
          </li>
          <li
            dir="ltr"
            style={{
              listStyleType: 'disc',
              fontSize: '12pt',
              fontFamily: 'Calibri,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 400,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre'
            }}
            aria-level={1}
          >
            <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '0pt', marginBottom: '0pt' }}>
              <span
                style={{
                  fontSize: '12pt',
                  fontFamily: 'Calibri,sans-serif',
                  color: '#000000',
                  backgroundColor: 'transparent',
                  fontWeight: 400,
                  fontStyle: 'normal',
                  fontVariant: 'normal',
                  textDecoration: 'none',
                  verticalAlign: 'baseline',
                  whiteSpace: 'pre-wrap'
                }}
              >
                Make misleading claims about IMPaCT Care, use offensive/abusive content, create fake
                websites/webpages/social media profiles/apps, misrepresent your connection to IMPaCT
                Care, or otherwise make any false or misleading statements to get a Referred CHW
                Candidate to use your link or code.
              </span>
            </p>
          </li>
          <li
            dir="ltr"
            style={{
              listStyleType: 'disc',
              fontSize: '12pt',
              fontFamily: 'Calibri,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 400,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre'
            }}
            aria-level={1}
          >
            <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '0pt', marginBottom: '0pt' }}>
              <span
                style={{
                  fontSize: '12pt',
                  fontFamily: 'Calibri,sans-serif',
                  color: '#000000',
                  backgroundColor: 'transparent',
                  fontWeight: 400,
                  fontStyle: 'normal',
                  fontVariant: 'normal',
                  textDecoration: 'none',
                  verticalAlign: 'baseline',
                  whiteSpace: 'pre-wrap'
                }}
              >
                Use your referral link or code in any manner that violates the law or the rights of
                anyone else.
              </span>
            </p>
          </li>
        </ul>
        <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '0pt', marginBottom: '0pt' }}>
          <span
            style={{
              fontSize: '12pt',
              fontFamily: 'Calibri,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 400,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre-wrap'
            }}
          >
            &nbsp;
          </span>
        </p>
        <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '0pt', marginBottom: '0pt' }}>
          <span
            style={{
              fontSize: '12pt',
              fontFamily: 'Calibri,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 400,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre-wrap'
            }}
          >
            Remember, when you share your referral link or code, you should explain that you are an
            IMPaCT Care Recruiter.
          </span>
        </p>
        <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '0pt', marginBottom: '0pt' }}>
          <span
            style={{
              fontSize: '12pt',
              fontFamily: 'Calibri,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 700,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre-wrap'
            }}
          >
            &nbsp;
          </span>
        </p>
        <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '0pt', marginBottom: '0pt' }}>
          <span
            style={{
              fontSize: '12pt',
              fontFamily: 'Calibri,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 400,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre-wrap'
            }}
          >
            If you are providing IMPaCT Care with the contact information for your Referred CHW
            Candidates, you represent that you have the right to provide that information.
          </span>
        </p>
        <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '0pt', marginBottom: '0pt' }}>
          <span
            style={{
              fontSize: '12pt',
              fontFamily: 'Calibri,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 400,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre-wrap'
            }}
          >
            &nbsp;
          </span>
        </p>
        <ol style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }} start={8}>
          <li
            dir="ltr"
            style={{
              listStyleType: 'decimal',
              fontSize: '12pt',
              fontFamily: 'Calibri,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 700,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre'
            }}
            aria-level={1}
          >
            <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '0pt', marginBottom: '0pt' }}>
              <span
                style={{
                  fontSize: '12pt',
                  fontFamily: 'Calibri,sans-serif',
                  color: '#000000',
                  backgroundColor: 'transparent',
                  fontWeight: 700,
                  fontStyle: 'normal',
                  fontVariant: 'normal',
                  textDecoration: 'none',
                  verticalAlign: 'baseline',
                  whiteSpace: 'pre-wrap'
                }}
              >
                Program Duration
              </span>
            </p>
          </li>
        </ol>
        {/* <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '0pt', marginBottom: '0pt' }}>
          <span
            style={{
              fontSize: '12pt',
              fontFamily: 'Calibri,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 400,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre-wrap'
            }}
          >
            &nbsp;
          </span>
        </p> */}
        <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '0pt', marginBottom: '0pt' }}>
          <span
            style={{
              fontSize: '12pt',
              fontFamily: 'Calibri,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 400,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre-wrap'
            }}
          >
            IMPaCT Care reserves the right to terminate the Referral Program at any time. Referrals
            made after the program termination date will not be eligible for referral awards.
          </span>
        </p>
        <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '0pt', marginBottom: '0pt' }}>
          <span
            style={{
              fontSize: '12pt',
              fontFamily: 'Calibri,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 400,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre-wrap'
            }}
          >
            &nbsp;
          </span>
        </p>
        <p>
          <br />
        </p>
        <p>
          <br />
        </p>
      </>
    </div>
  );
};

export default TermsAndConditionsText;
