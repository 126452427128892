import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker as MaterialDatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import clsx from 'clsx';
import dayjs from 'dayjs';
import { useState } from 'react';
import CalendarMonth from '@mui/icons-material/CalendarMonth';

import style from './datePicker.module.scss';

type DatePickerProps = {
  disabled?: boolean;
  value: any;
  onBlur?: any;
  onChange: any;
  onFocus?: any;
  disablePast?: boolean;
  placeholder?: string;
  minDate?: any;
  classnamesProps?: any;
  disableFuture?: boolean;
};

const BrowserInput = (props: any) => {
  const { inputProps, InputProps, inputRef, ownerState, error, ...other } = props;

  return (
    <div ref={InputProps?.ref}>
      <input
        ref={inputRef}
        {...inputProps}
        {...other}
        disabled={ownerState?.disabled}
        onFocus={ownerState?.onFocus}
        onBlur={ownerState?.onBlur}
        placeholder={ownerState?.format}
      />
    </div>
  );
};

const DatePicker = (props: DatePickerProps) => {
  const [open, setOpen] = useState<boolean>(false);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <div className={clsx(style.datePicker, props.classnamesProps)}>
        <span onClick={() => setOpen(true)}>
          <CalendarMonth />
        </span>
        <MaterialDatePicker
          {...props}
          open={open}
          onOpen={() => setOpen(true)}
          onClose={() => setOpen(false)}
          disablePast={props.disablePast}
          format={'MM/DD/YYYY'}
          value={props.value ? dayjs(props.value) : ''}
          onChange={(newValue) => {
            props.onChange(dayjs(newValue).isValid() ? dayjs(newValue).format('MM/DD/YYYY') : '');
          }}
          onAccept={props.onBlur}
          slots={{
            textField: BrowserInput
          }}
          disableFuture={props.disableFuture}
        />
      </div>
    </LocalizationProvider>
  );
};

export default DatePicker;
