import clsx from 'clsx';
import { useFormik } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';
import { Button, InputField } from '../../../components';
import style from './step1SignUp.module.scss';

import {
  EMPTY_SPACES_REGEX,
  LATIN_CHARS_REGEX,
  LOGIN_PAGE_URL,
  PLACEHOLDER_EMAIL,
  PLACEHOLDER_LAST_NAME,
  PLACEHOLDER_PHONE,
  US_PHONE_REGEX
} from '../../../constants';
import { updateUserData } from '../../../store/auth/authSlice';

import { Autocomplete } from 'components/autocomplete';
import { DatePicker } from 'components/datePicker';
import debounce from 'lodash.debounce';
import {
  getCitiesAutocomplete,
  getStatesAutocomplete,
  getZipCodesAutocomplete
} from 'store/auth/authActions';
import { Logo } from '../../../components';
import { PhoneInputField } from '../../../components/inputField/phoneInputField';
import { PLACEHOLDER_FIRST_NAME } from '../../../constants/index';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import {
  MSG_ENGLISH_LETTERS_ONLY,
  MSG_FIELD_LESS_100_CHARS,
  MSG_FIELD_LESS_40_CHARS,
  MSG_VALID_PHONE_FORMAT,
  MSG_WHITE_SPACE_ONLY
} from '../../../utils/errorMessages';
import dayjs from 'dayjs';

type Step1SignUpProps = {
  handleClickOnNextBtn?: () => void;
};

const Step1SignUp: React.FunctionComponent<Step1SignUpProps> = ({ handleClickOnNextBtn }) => {
  const [cityInputValue, setCityInputValue] = useState<string>('');
  const [stateInputValue, setStateInputValue] = useState<string>('');
  const [zipCodeInputValue, setZipCodeInputValue] = useState<string>('');
  const userState = useAppSelector((state) => state.auth.user);
  const cities = useAppSelector((state) => state.auth.citiesAutocomplete);
  const states = useAppSelector((state) => state.auth.statesAutocomplete);
  const zipCodes = useAppSelector((state) => state.auth.zipCodesAutocomplete);

  const dispatch = useAppDispatch();
  const formik = useFormik({
    validateOnMount: true,
    initialValues: {
      ...userState
    },
    validationSchema: Yup.object({
      firstName: Yup.string()
        .max(40, MSG_FIELD_LESS_40_CHARS)
        .matches(EMPTY_SPACES_REGEX, MSG_WHITE_SPACE_ONLY)
        .matches(LATIN_CHARS_REGEX, MSG_ENGLISH_LETTERS_ONLY)
        .required('First name is required.'),
      lastName: Yup.string()
        .max(40, MSG_FIELD_LESS_40_CHARS)
        .matches(EMPTY_SPACES_REGEX, MSG_WHITE_SPACE_ONLY)
        .matches(LATIN_CHARS_REGEX, MSG_ENGLISH_LETTERS_ONLY)
        .required('Last name is required.'),
      email: Yup.string().email('Invalid email address').required('Email address is required.'),
      // TODO- check if user is in system already--> potential sec. risk
      // // .test('Email check', 'Email already exists', async (email) => {
      // //   let isExisting = false;
      // //   if (email && email?.length >= 5) {
      // //     isExisting = await checkIfEmailIsValid(email);
      // //     return isExisting;
      // //   }
      // //   return isExisting;
      // // }),
      phone: Yup.string()
        .matches(US_PHONE_REGEX, MSG_VALID_PHONE_FORMAT)
        .required('Phone number is required.'),
      street: Yup.string().max(100, MSG_FIELD_LESS_100_CHARS).required('Street is required.'),
      addressLine2: Yup.string().max(100, MSG_FIELD_LESS_100_CHARS),
      city: Yup.string().required('City is required.'),
      state: Yup.string().required('State is required.'),
      zip: Yup.string().required('Zip is required.'),
      dob: Yup.string()
        .required('Date of Birth is required.')
        .test(
          'dob',
          'You can only add a date in the past',
          async (fieldVal): Promise<boolean | Yup.ValidationError | any> => {
            const today = dayjs();
            const selectedDate = dayjs(fieldVal);
            const isStartDateInPast = selectedDate.isAfter(today, 'd');

            return !isStartDateInPast;
          }
        )
    }),

    onSubmit: (values) => {
      //update global state
      dispatch(updateUserData(values));

      //Handle prop-function for next step
      if (handleClickOnNextBtn) {
        handleClickOnNextBtn();
      }
    }
  });
  const debouncedFetchCities = useRef(
    debounce(
      async ({ city, state, zipCode }: { zipCode: string; city?: string; state: string }) => {
        dispatch(getCitiesAutocomplete({ term: city, state, zipCode }));
      },
      300
    )
  ).current;

  const debouncedFetchStates = useRef(
    debounce(
      async ({ state, city, zipCode }: { zipCode: string; city: string; state?: string }) => {
        dispatch(getStatesAutocomplete({ term: state, city, zipCode }));
      },
      300
    )
  ).current;

  const debouncedFetchZipCodes = useRef(
    debounce(
      async ({ zipCode, city, state }: { zipCode?: string; city: string; state: string }) => {
        dispatch(getZipCodesAutocomplete({ term: zipCode, city, state }));
      },
      300
    )
  ).current;

  useEffect(() => {
    debouncedFetchCities({ state: formik.values.state, zipCode: formik.values.zip });
  }, [formik.values.state, formik.values.zip]);

  useEffect(() => {
    debouncedFetchStates({ city: formik.values.city, zipCode: formik.values.zip });
  }, [formik.values.city, formik.values.zip]);

  useEffect(() => {
    debouncedFetchZipCodes({ city: formik.values.city, state: formik.values.state });
  }, [formik.values.city, formik.values.state]);

  useEffect(() => {
    return () => {
      debouncedFetchStates.cancel();
      debouncedFetchZipCodes.cancel();
      debouncedFetchCities.cancel();
    };
  }, [debouncedFetchCities]);

  const handleOnCityInputChange = async (_: any, value: string, reason: string) => {
    setCityInputValue(value);
    if (reason === 'reset') {
      return;
    }
    if ((value === '' || value == null) && formik.values.city) {
      return;
    }
    await debouncedFetchCities({
      city: value,
      state: formik.values.state,
      zipCode: formik.values.zip
    });
  };

  const handleOnStateInputChange = async (_: any, value: string, reason: string) => {
    setStateInputValue(value);
    if (reason === 'reset') {
      return;
    }
    if ((value === '' || value == null) && formik.values.state) {
      return;
    }
    await debouncedFetchStates({
      state: value,
      city: formik.values.city,
      zipCode: formik.values.zip
    });
  };

  const handleOnZipCodeInputChange = async (_: any, value: string, reason: string) => {
    setZipCodeInputValue(value);
    if (reason === 'reset') {
      return;
    }
    if ((value === '' || value == null) && formik.values.zip) {
      return;
    }
    await debouncedFetchZipCodes({
      zipCode: value,
      city: formik.values.city,
      state: formik.values.state
    });
  };

  return (
    <div className={style.container}>
      <div className={style.logoContainer}>
        <Logo type="orange" />
      </div>
      <h1 className={style.h1TitleAccount}>Create an account</h1>
      <form onSubmit={formik.handleSubmit}>
        {/* first name field */}
        <div className={style.flexGap}>
          <div className={style.inputGroup}>
            <label htmlFor="firstName">
              First Name <span>*</span>
            </label>
            <InputField
              id="firstName"
              name="firstName"
              type="text"
              placeholder={PLACEHOLDER_FIRST_NAME}
              classnamesProps={[
                formik.touched.firstName && formik.errors.firstName && 'globalErrorBorder',
                formik.touched.firstName && !formik.errors.firstName && 'globalValidBorder'
              ]}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.firstName}
            />
            {formik.touched.firstName && formik.errors.firstName ? (
              <div className={style.fieldError}>{formik.errors.firstName}</div>
            ) : null}
          </div>

          {/* last name field */}
          <div className={style.inputGroup}>
            <label htmlFor="lastName">
              Last Name <span>*</span>
            </label>
            <InputField
              id="lastName"
              name="lastName"
              type="text"
              placeholder={PLACEHOLDER_LAST_NAME}
              classnamesProps={[
                formik.touched.lastName && formik.errors.lastName && 'globalErrorBorder',
                formik.touched.lastName && !formik.errors.lastName && 'globalValidBorder'
              ]}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.lastName}
            />
            {formik.touched.lastName && formik.errors.lastName ? (
              <div className={style.fieldError}>{formik.errors.lastName}</div>
            ) : null}
          </div>
        </div>
        {/* email field */}
        <div className={style.inputGroup}>
          <label htmlFor="email">
            Email <span>*</span>
          </label>
          <InputField
            id="email"
            name="email"
            type="email"
            placeholder={PLACEHOLDER_EMAIL}
            classnamesProps={[
              formik.touched.email && formik.errors.email && 'globalErrorBorder',
              formik.touched.email && !formik.errors.email && 'globalValidBorder'
            ]}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.email}
          />
          {formik.touched.email && formik.errors.email ? (
            <div className={style.fieldError}>{formik.errors.email}</div>
          ) : null}
        </div>
        <div className={style.flexGap}>
          <div className={style.inputGroup}>
            <label htmlFor="street">
              Street Address <span>*</span>
            </label>
            <InputField
              id="street"
              name="street"
              placeholder="123 Example Street"
              classnamesProps={[
                formik.touched.street && formik.errors.street && 'globalErrorBorder',
                formik.touched.street && !formik.errors.street && 'globalValidBorder'
              ]}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.street}
            />
            {formik.touched.street && formik.errors.street ? (
              <div className={style.fieldError}>{formik.errors.street}</div>
            ) : null}
          </div>
          <div className={style.inputGroup}>
            <label htmlFor="addressLine2">Address Line 2</label>
            <InputField
              id="addressLine2"
              name="addressLine2"
              placeholder="Apt/Unit #"
              classnamesProps={[
                formik.touched.addressLine2 && formik.errors.addressLine2 && 'globalErrorBorder',
                formik.touched.addressLine2 && !formik.errors.addressLine2 && 'globalValidBorder'
              ]}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.addressLine2}
            />
            {formik.touched.addressLine2 && formik.errors.addressLine2 ? (
              <div className={style.fieldError}>{formik.errors.addressLine2}</div>
            ) : null}
          </div>
        </div>
        <div className={style.flexGap}>
          <div className={style.inputGroup}>
            <label htmlFor="city">
              City <span>*</span>
            </label>
            <Autocomplete
              id="city"
              loadingText="Loading..."
              loading={cities.loading}
              clearOnBlur={false}
              onChange={(_, value) => {
                formik.setFieldValue('city', value ?? '');
              }}
              classnamesProps={[
                formik.touched.city && formik.errors.city && 'globalErrorBorder',
                formik.touched.city && !formik.errors.city && 'globalValidBorder'
              ]}
              openOnFocus
              onBlur={formik.handleBlur}
              onInputChange={handleOnCityInputChange}
              inputValue={cityInputValue}
              value={formik.values.city}
              name="city"
              placeholder="City"
              options={cities.data.map((option) => option.name)}
            />
            {formik.touched.city && formik.errors.city ? (
              <div className={style.fieldError}>{formik.errors.city}</div>
            ) : null}
          </div>
          <div className={style.inputGroup}>
            <label htmlFor="state">
              State <span>*</span>
            </label>
            <Autocomplete
              id="state"
              openOnFocus
              loadingText="Loading..."
              loading={states.loading}
              clearOnBlur={false}
              onChange={(_, value) => {
                formik.setFieldValue('state', value ?? '');
              }}
              classnamesProps={[
                formik.touched.state && formik.errors.state && 'globalErrorBorder',
                formik.touched.state && !formik.errors.state && 'globalValidBorder'
              ]}
              onBlur={formik.handleBlur}
              onInputChange={handleOnStateInputChange}
              inputValue={stateInputValue}
              value={formik.values.state}
              name="state"
              placeholder="State"
              options={states.data.map((option) => option.name)}
            />
            {formik.touched.state && formik.errors.state ? (
              <div className={style.fieldError}>{formik.errors.state}</div>
            ) : null}
          </div>
          <div className={style.inputGroup}>
            <label htmlFor="zip">
              ZIP <span>*</span>
            </label>
            <Autocomplete
              id="zip"
              openOnFocus
              loadingText="Loading..."
              loading={zipCodes.loading}
              clearOnBlur={false}
              onChange={(_, value) => {
                formik.setFieldValue('zip', value ?? '');
              }}
              classnamesProps={[
                formik.touched.zip && formik.errors.zip && 'globalErrorBorder',
                formik.touched.zip && !formik.errors.zip && 'globalValidBorder'
              ]}
              onBlur={formik.handleBlur}
              onInputChange={handleOnZipCodeInputChange}
              inputValue={zipCodeInputValue}
              value={formik.values.zip}
              name="zip"
              placeholder="ZIP"
              options={zipCodes.data.map((option) => option.name)}
            />
            {formik.touched.zip && formik.errors.zip ? (
              <div className={style.fieldError}>{formik.errors.zip}</div>
            ) : null}
          </div>
        </div>
        {/* phone Number field */}
        <div className={style.flexGap}>
          <div className={style.inputGroup}>
            <label htmlFor="dob">
              Date of Birth <span>*</span>
            </label>
            <DatePicker
              value={formik.values.dob}
              onChange={(value: string) => formik.setFieldValue('dob', value)}
              onBlur={() => formik.setFieldTouched('dob', true)}
              disableFuture
            />
            {formik.touched.dob && formik.errors.dob ? (
              <div className={style.fieldError}>{formik.errors.dob}</div>
            ) : null}
          </div>
          <div className={style.inputGroup}>
            <label htmlFor="phone">
              Phone Number <span>*</span>
            </label>
            <PhoneInputField
              id="phone"
              name="phone"
              placeholder={PLACEHOLDER_PHONE}
              classnamesProps={[
                formik.touched.phone && formik.errors.phone && 'globalErrorBorder',
                formik.touched.phone && !formik.errors.phone && 'globalValidBorder'
              ]}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.phone}
            />
            {formik.touched.phone && formik.errors.phone ? (
              <div className={style.fieldError}>{formik.errors.phone}</div>
            ) : null}
          </div>
        </div>

        {/* next button */}
        <div className={style.inputGroup}>
          <Button isSecondary text={'Next'} type={'submit'} disabled={!formik.isValid} />
        </div>

        {/* go to login page */}
        <div className={clsx(style.inputGroup, style.haveAccount)}>
          <p>Have an account?</p> <Link to={LOGIN_PAGE_URL}>Log in</Link>
        </div>
      </form>
    </div>
  );
};

export default Step1SignUp;
